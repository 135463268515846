<template>
  <div v-loading="loading" class="classify">
    <div class="classifyBons">
      <div class="classifyBox">
        <span>分类：</span>
        <div class="classifyItem">
          <p @click="articleClick('')">
            全部 <i v-if="articleClassify === ''" class="iconfont">&#xe745;</i>
          </p>
          <p
            v-for="(item, index) in classifyList"
            :key="index"
            @click="articleClick(item.classifyName)"
          >
            {{ item.classifyName }}
            <i v-if="articleClassify === item.classifyName" class="iconfont"
              >&#xe745;</i
            >
          </p>
        </div>
      </div>
      <div class="classifyBox" style="margin-top: 20px">
        <span>标签：</span>
        <div class="classifyItem">
          <p @click="tabsAll">
            全部 <i v-if="labelId.length === 0" class="iconfont">&#xe745;</i>
          </p>
          <p
            v-for="(item, index) in labelList"
            :key="index"
            @click="tabsClick(item.labelId)"
          >
            {{ item.labelName }}
            <i v-if="labelId.includes(item.labelId)" class="iconfont"
              >&#xe745;</i
            >
          </p>
        </div>
      </div>
    </div>
    <!-- 文章列表 -->
    <div class="ArticleList">
      <div
        class="ArticleItem"
        v-for="(item, index) in ArticleList"
        :key="index"
      >
        <router-link
          v-if="item.thumb != ''"
          class="Image"
          to=""
          @click="Details(item.articleId, item.articleTitle)"
        >
          <img :src="'https://api.lphadmin.cn/uploads/' + item.thumb" alt="" />
        </router-link>
        <h2 class="Title">
          <router-link
            to=""
            @click="Details(item.articleId, item.articleTitle)"
            >{{ item.articleTitle }}</router-link
          >
        </h2>
        <div class="Digest">{{ item.articleDigest }}</div>
        <div class="Details">
          <p>
            <span class="icode"
              ><i class="iconfont">&#xe600;</i> {{ item.creationTime }}</span
            >
            <span class="icode"
              ><i class="iconfont">&#xe6a7;</i> {{ item.articleClassify }}</span
            >
            <span class="icode">
              <a v-for="(items, index) in item.articleLable" :key="index">
                <span v-for="(i, index) in labelList" :key="index">
                  <el-tag
                    v-if="items === i.labelId"
                    :style="{
                      color: i.labelColor,
                      backgroundColor: i.labelBgc,
                      borderColor: i.labelBgc,
                    }"
                    @click="labelClick(i)"
                    >{{ i.labelName }}</el-tag
                  >
                </span>
              </a>
            </span>
          </p>
          <a @click="Details(item.articleId, item.articleTitle)">阅读全文</a>
        </div>
      </div>
    </div>
    <div class="paging">
      <span v-if="formArticle.page != 1" @click="nextPage('page')">上一页</span>
      <span
        v-for="item in pages"
        :key="item"
        @click="breaks(item)"
        :class="{
          active: formArticle.page === item,
        }"
        >{{ item }}</span
      >
      <span v-if="formArticle.page != pages" @click="nextPage('next')"
        >下一页</span
      >
    </div>
  </div>
</template>
<script setup name="Classify">
import { getArticle } from "@/api/article";
import { getLabel } from "@/api/label";
import { getClassify } from "@/api/classify";
import { ref, onMounted } from "vue";
import router from "@/router";
import { useStore } from "vuex";
const loading = ref(false);
const store = useStore();
onMounted(() => {
  getLabels(); // 获取标签
  getClassifys();
  getArticleList();
});
const labelId = ref([]);
const articleClassify = ref("");
// 获取标签
const labelList = ref([]);
const getLabels = () => {
  getLabel().then((res) => {
    if (res.code === 200) {
      labelList.value = res.data.data;
    }
  });
};
// 获取分类
const classifyList = ref([]);
const getClassifys = () => {
  getClassify().then((res) => {
    if (res.code === 200) {
      classifyList.value = res.data.data;
    }
  });
};

// 分类的点击
const articleClick = (str) => {
  articleClassify.value = str;
  getArticleList();
};
// 标签的点击
const tabsAll = () => {
  labelId.value = [];
  getArticleList();
};
const tabsClick = (id) => {
  if (labelId.value.includes(id)) {
    labelId.value = labelId.value.filter((item) => {
      return item !== id;
    });
  } else {
    labelId.value.push(id);
  }
  formArticle.value.page = 1;
  getArticleList();
};

const formArticle = ref({
  limit: 5,
  page: 1,
});
// 文章总数
const articleNum = ref(0);
// 文章列表
const ArticleList = ref([]);
const getArticleList = () => {
  loading.value = true;
  let obj = {
    limit: formArticle.value.limit,
    page: formArticle.value.page,
    articleLable: labelId.value ? labelId.value.join() : "",
    articleClassify: articleClassify.value,
  };
  getArticle(obj).then((res) => {
    if (res.code === 200) {
      articleNum.value = res.data.total;
      ArticleList.value = [];
      res.data.data.forEach((v) => {
        v.articleLable = JSON.parse(v.articleLable);
        ArticleList.value.push(v);
      });
      pageImgNum();
      setTimeout(() => {
        loading.value = false;
      }, 1000);
    } else {
      loading.value = false;
    }
  });
};
const pages = ref();
// 分页数
const pageImgNum = () => {
  pages.value = Math.ceil(articleNum.value / 5);
};
// 分页按钮
const breaks = (num) => {
  formArticle.value.page = num;
  getArticleList();
};
const nextPage = (type) => {
  if (type === "page") {
    formArticle.value.page = formArticle.value.page - 1;
  } else {
    formArticle.value.page = formArticle.value.page + 1;
  }
  getArticleList();
};
// 跳转详情
const Details = (id, title) => {
  let obj = {
    id,
    title,
  };
  store.dispatch("sum_setArticleId", obj);
  router.push({
    path: "/details",
    query: {
      id,
    },
  });
};
// label点击
const labelClick = (i) => {
  console.log(i);
  router.push({
    path: "/search",
    query: {
      type: i.labelId,
      typeLable: i.labelName,
    },
  });
};
</script>
<style scoped lang="scss">
.classify {
  width: 100%;
  box-sizing: border-box;
  .classifyBons {
    padding: 20px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
  }
  .classifyBox {
    display: flex;
    align-items: center;
    span {
      font-size: 16px;
    }
    .classifyItem {
      display: flex;
      align-items: center;
      p {
        position: relative;
        padding: 2px 6px;
        background-color: rgba(64, 158, 255, 0.2);
        border-radius: 2px;
        margin-right: 10px;
        cursor: pointer;
        .iconfont {
          position: absolute;
          top: -5px;
          right: -5px;
          color: green;
          font-size: 12px;
        }
      }
    }
  }
}
.ArticleList {
  .ArticleItem {
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    margin-top: 20px;
    padding: 10px;
    box-sizing: border-box;
    font-size: 16px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);

    .Image {
      display: flex;
      justify-content: center;
      border-radius: 4px;
      width: 100%;
      overflow: hidden;
      img {
        width: 100%;
        height: 320px;
        transition: transform 3s ease-out, opacity 0.5s ease-out;
        &:hover {
          transform: scale(1.2);
        }
      }
    }
    .Title {
      padding: 15px 0 10px 10px;
      a {
        text-decoration: none;
        font-size: 22px;
        color: #333;
      }
    }
    .Digest {
      color: #333;
      opacity: 0.8;
      font-size: 18px;
      padding: 0 0 0 10px;
    }
    .Details {
      padding: 20px 10px 10px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #333;
      opacity: 0.8;
      font-size: 16px;
      p {
        display: flex;
        align-items: center;
        .icode {
          margin-right: 10px;
          a {
            margin-right: 5px;
            cursor: pointer;
          }
        }
      }
      a {
        cursor: pointer;
        text-decoration: none;
        font-weight: 700;
        color: #409eff;
        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
}
.paging {
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  background-color: #fff;
  text-align: center;
  span {
    padding: 0 10px;
    margin: 0 5px;
    cursor: pointer;
    &:hover {
      border-bottom: 1px solid #e8e8e8;
    }
  }
  .active {
    color: #409eff;
  }
}
</style>

import request from "@/utils/request";

/**
 * 获取分类列表
 * @returns
 */
export function getClassify(data) {
  return request({
    url: "/classify/select",
    method: "GET",
    params: data,
  });
}
